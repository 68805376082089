import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import api from '../../helpers/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import GiftVoucherRedemption from '../elements/payments/GiftVoucherRedemption'; 
import LessonPackages from '../elements/payments/LessonPackages'; 
import Spinner from '../elements/Spinner';

class Payments extends React.Component {
    state = {
        studentData: null,
        submitting: false,
        referringPage: ""
    };

    constructor(props) {
        super(props);
        this.state.referringPage = this.props.location.returnPath;
        this.fetchStudentData();
    };

    backToReferrer = () => {
        if(typeof(this.state.referringPage) === 'undefined' || this.state.referringPage === '') {
            this.props.history.push('/students/' + this.props.match.params.student_id);
        } else {
            this.props.history.push(this.state.referringPage);
        }
    };

    changeSubmitting(){
        this.setState({
            submitting: !this.state.submitting
        });
    }

    fetchStudentData = (params = {}) => {
        api.post("students/fetch/" + this.props.match.params.student_id, {
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                ...params
            })
        })
        .then(res => {
            //Check for an error response (status of "NOK")
            if(res.status === 'NOK' || typeof res.result.student === 'undefined') {
                this.props.history.push('/students/' + this.props.match.params.student_id);
            } else {
                this.setState({
                    studentData: res.result.student
                });
            }
        })
        .catch(error => {
            console.log(error);
            this.props.history.push('/students/' + this.props.match.params.student_id);
        });
    };

    render(){
        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    {this.state.submitting && (
                        <Spinner type="mega" />
                    )}
                    {!this.state.submitting && (
                        <>
                            <Button onClick={() => this.backToReferrer()} className="ant-btn goBack">
                                <FontAwesomeIcon icon={ faArrowLeft } />
                                Return
                            </Button>
                            <div className={this.state.submitting ? "hidden" : ""}>
                                <h2>Redeem a Gift Voucher</h2>
                                <GiftVoucherRedemption 
                                    student_id={this.props.match.params.student_id}
                                    chosen_driver={this.props.chosen_driver} 
                                    goBack={() => this.backToReferrer()}
                                    changeSubmitting={() => this.changeSubmitting()}
                                />
                                <hr></hr>
                                <h2>Purchase a Lesson Package</h2>
                                <LessonPackages
                                    history={this.props.history}
                                    student_id={this.props.match.params.student_id}
                                    studentData={this.state.studentData}
                                    chosen_driver={this.props.chosen_driver} 
                                    backToReferrer={() => this.backToReferrer()}
                                    changeSubmitting={() => this.changeSubmitting()}
                                    duration={typeof this.props.match.params.duration !== 'undefined' ? this.props.match.params.duration : 1}
                                />
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        );
    }
};

export default withRouter(Payments);