import React from 'react';
import { withRouter } from 'react-router-dom';
import {Link} from 'react-router-dom';
import { Row, Col, Form, Input, Select, DatePicker, Button } from 'antd';
import api from '../../helpers/api';
import Spinner from '../elements/Spinner';
import config from '../../config';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';

const { Option } = Select;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const { brand } = config;

let suburbLookupTimeout;
let currentSuburb;

class StudentAdd extends React.Component {
    state = {
        loading: false,
        submitting: false,
        referral_code_option: false,
        suburbs: [],
        studentList: []
    };

    componentDidMount() {
        this.fetchMyStudents();
    }

    fetchMyStudents = (params = {}) => {
        this.setState({ loading: true });

        api.post("students/my",{
            body: JSON.stringify({
                chosen_driver: this.props.chosen_driver,
                ...params
            })
        })
        .then(data => {
            this.setState({
                loading: false,
                studentList: data.result.students
            });
        });
    };

    selectReferringStudent = (item) => {
        console.log(`Referring Student \`${item.label}\` (${item.key})`);
        this.setState({
            referral_code_option: (item.key === 'other' ? true : false)
        }, () => {
            if (item.key === 'other') {
                document.getElementById('student_add_form_referring_student_code').value = '';
            }
        });
    };

    disableAutoComplete = () => {
        if (!this.state.autoCompleteDisabled) {
            const elementsToDisable = document.getElementsByClassName("ant-select-search__field");
            for (let i = 0; i < elementsToDisable.length; i++) {
                elementsToDisable[i].setAttribute("autocomplete","any-arbitrary-text");
            }
            this.setState({ autoCompleteDisabled: true });
        }
    };

    handleSearch = value => {
        this.suburbLookup(value, suburbs => this.setState({ suburbs }));
    };

    suburbLookup = (value, callback) => {
        if (suburbLookupTimeout) {
            clearTimeout(suburbLookupTimeout);
            suburbLookupTimeout = null;
        }
        currentSuburb = value;

        function debouncedLookup() {
            api.post("suburbs/search", {
                body: JSON.stringify({
                    search: value
                })
            })
            .then(d => {
                if (currentSuburb === value) {
                    const data = [];
                    d.result.suburbs.forEach(r => {
                        data.push({
                            value: r.id,
                            text: r.suburb_name
                        });
                    });
                    callback(data);
                }
            });
        }
        suburbLookupTimeout = setTimeout(debouncedLookup, 300);
    }

    handleSave = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({submitting: true}, () => {
                    values.referring_student_id = (typeof(values.student.student_referral) !== 'undefined' && values.student.student_referral.key !== 'other' ? values.student.student_referral.key : null);
                    values.chosen_driver = this.props.chosen_driver;
                    values.source = 'Diary';
                    delete values.student.student_referral;
                    return api.post("students/add", {
                        body: JSON.stringify(values)
                    })
                    .then(res => {
                        //Convert to JSON in case we've received a string response
                        if(typeof res === 'string'){
                            res = JSON.parse(res);
                        }
                        //Check for an error response (status of "NOK")
                        if(res.status === 'NOK'){
                            this.setState({
                                errorMessage: res.result.error,
                                errorMessageType: 'error',
                                submitting: false
                            });
                        } else {
                            this.props.history.push('/students/' + res.result.id);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            errorMessage: "Error saving Student, please try again.",
                            errorMessageType: 'error',
                            submitting: false
                        });
                    });
                });
            }
        });
    };

    render(){
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 7},
                xl: {span: 9}
            },
            wrapperCol: {
                xs: {span: 17},
                xl: {span: 15}
            }
        };
        const suburbs = this.state.suburbs.map(d => <Option key={d.value}>{d.text}</Option>);

        return (
            <Row type="flex" justify="space-around" id="mainBody">
                <Col span={24}>
                    {
                    this.state.loading ? 
                        (
                            <Spinner type="mega" />
                        ) : (
                            <>
                                <h2>New Student</h2>
                                <Form {...formItemLayout} id="studentAddForm" onSubmit={this.handleSave}>
                                    <fieldset>
                                        <legend>Personal</legend>
                                        <Form.Item label="First Name" hasFeedback >
                                            {getFieldDecorator('first_name', {
                                                rules: [{ required: true, message: 'Please enter a First Name.' }],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Last Name" hasFeedback >
                                            {getFieldDecorator('last_name', {
                                                rules: [{ required: true, message: 'Please enter a Last Name.' }],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        {brand !== 'passfirstgo' && (
                                            <Form.Item label="Date of Birth" hasFeedback >
                                                {getFieldDecorator('dob', {
                                                })(
                                                    <DatePicker
                                                        size="large"
                                                        allowClear={false}
                                                        placeholder='Select Date of Birth'
                                                        format={dateFormatList}
                                                    />
                                                )}
                                            </Form.Item>
                                        )}
                                    </fieldset>
                                    <fieldset>
                                        <legend>Address</legend>
                                        <Form.Item label="Street Number" hasFeedback >
                                            {getFieldDecorator('address.street_number', {
                                                rules: [
                                                    { required: true, message: 'Please enter a Street Number.' },
                                                    { max: 10, message: 'Street Number cannot be more than 10 characters.' },
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Street Name" hasFeedback >
                                            {getFieldDecorator('address.street_name', {
                                                rules: [{ required: true, message: 'Please enter a Street Name.' }],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Suburb" hasFeedback >
                                            {getFieldDecorator('address.suburb_id', {
                                                rules: [{ required: true, message: 'Please enter a Suburb.' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    placeholder="Select Suburb"
                                                    defaultActiveFirstOption={false}
                                                    showArrow={false}
                                                    filterOption={false}
                                                    onSearch={this.handleSearch}
                                                    onFocus={this.disableAutoComplete}
                                                    notFoundContent={null}
                                                >
                                                    {suburbs}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </fieldset>
                                    <fieldset>
                                        <legend>Contact</legend>
                                        <Form.Item label="Mobile" hasFeedback >
                                            {getFieldDecorator('mobile_phone', {
                                                rules: [
                                                    { required: true, message: 'Please enter a Mobile Phone Number.' },
                                                    { max: 10, message: 'Mobile Phone Number cannot be more than 10 characters.' },
                                                ],
                                            })(
                                                <Input
                                                    maxLength={10}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Email" hasFeedback >
                                            {getFieldDecorator('email', {
                                                rules: [{ required: true, message: 'Please enter an Email Address.' }],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                        {brand !== 'passfirstgo' && (
                                            <>
                                                <Form.Item label="Was this a Student Referral?" hasFeedback >
                                                    {getFieldDecorator('student.student_referral', {
                                                        rules: [{ required: false, message: 'Please select a Student.' }]
                                                    })(
                                                        <Select
                                                            labelInValue
                                                            showSearch
                                                            placeholder="Select a Student"
                                                            optionFilterProp="children"
                                                            onChange={this.selectReferringStudent}
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            <Option value="other">Other</Option>
                                                            {
                                                                this.state.studentList.map((student, index) => {
                                                                    return <Option key={student.id} value={student.id}>{student.first_name + " " + student.last_name}</Option>;
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="Referral Code" style={{display: (this.state.referral_code_option ? 'block' : 'none')}} hasFeedback >
                                                    {getFieldDecorator('referring_student_code', {
                                                        rules: [{ message: 'If you have a referral code, please enter it' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </Form.Item>
                                            </>
                                        )}
                                    </fieldset>

                                    {brand !== 'passfirstgo' && (
                                        <fieldset>
                                            <legend>Licence Details</legend>
                                            <Form.Item label="Type" hasFeedback>
                                                {getFieldDecorator('student.licence_type', {
                                                    rules: [{ required: true, message: 'Please select a Licence Type.' }],
                                                    initialValue: 'Learner'
                                                })(
                                                    <Select
                                                        placeholder='Select a Licence Type'
                                                    >
                                                        <Option value="Learner">Learner</Option>
                                                        <Option value="Provisional">Provisional</Option>
                                                        <Option value="Aged">Aged</Option>
                                                        <Option value="Overseas">Overseas</Option>
                                                        <Option value="Full">Full</Option>
                                                        <Option value="Unknown">Unknown</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Number" hasFeedback >
                                                {getFieldDecorator('student.licence_number', {
                                                })(
                                                    <Input />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Expiry" hasFeedback >
                                                {getFieldDecorator('student.licence_expiry', {
                                                })(
                                                    <DatePicker
                                                        size="large"
                                                        allowClear={false}
                                                        placeholder='Select Expiry Date'
                                                        format={dateFormatList}
                                                    />
                                                )}
                                            </Form.Item>
                                        </fieldset>
                                    )}

                                    <div className='form-buttons'>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={this.state.submitting}
                                            loading={this.state.submitting}
                                        >
                                            <FontAwesomeIcon icon={faSave} />Save Student
                                        </Button>
                                        <Link
                                            to="/"
                                        >
                                            <Button
                                                type="danger"
                                                htmlType="button"
                                                disabled={this.state.submitting}
                                                loading={this.state.submitting}
                                            >
                                                <FontAwesomeIcon icon={faBan} />Cancel
                                            </Button>
                                        </Link>
                                    </div>
                                </Form>
                            </>
                        )
                    }
                </Col>
            </Row>
        );
    }
};

const StudentAddForm = Form.create({name: 'student_add_form'})(StudentAdd);

export default withRouter(StudentAddForm);
